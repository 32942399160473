<template>
    <div class="analysis">
        <div class="content_box_">
            <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
            <div class="title_bg_text_">男女双方性格</div>
            <div class="gossip_bg_box_">
                <span class="man_name_ name_text__">{{data_list.scwg.self_scwg.name}} <br> <i class="designation__">男主角</i> </span>
                <span class="girl_name_ name_text__">{{data_list.scwg.other_scwg.name}} <br><i class="designation__">女主角</i> </span>
            </div>
            <p class="text_">
                根据你们的姓名五行能量、时间进行起卦，其中
                体卦代表本人的性格，用卦代表你求测另一半的
                性格。
            </p>
            <p class="text_"><span class="overstriking_">本人性格分析</span> <br> {{data_list.xg.self}}</p>
            <p class="text_"><span class="overstriking_">另一半性格分析</span> <br>{{data_list.xg.other}}</p>
            <p class="text_">
                特别说明:若遇到男女双方性格一样，如都是果决
                或者安于现状的性格，在相处过程中需要学会互
                相谦让或者是学会突破自我，避免由于性格类似
                ，引发感情危机。
            </p>
        </div>
        <div class="content_box_">
            <div class="flower_img_"><img src="https://cdn.zhiyileiju.cn/WechatIMG543.png" alt=""></div>
            <div class="title_bg_text_">双方感情匹配度分析</div>
            <div class="gossip_bg_box_">
                <span class="man_name_ name_text__">{{data_list.scwg.self_scwg.name}} <br> <i class="designation__">男主角</i> </span>
                <span class="girl_name_ name_text__">{{data_list.scwg.other_scwg.name}} <br><i class="designation__">女主角</i> </span>
            </div>
            <p class="text_">
                根据你们的姓名五行能量、时间进行起卦，其中
                <span class="text_red__">本卦代表你们双方感情相处的现状</span>，<span class="text_red__">互卦代表你
                们未来相处的过程</span> 、容易遇到的情感问题，<span class="text_red__">变卦
                代表你们相处的结果</span>、最终感情的走向。
            </p>
            <h2 class="title_text__">双方感情现状分析</h2>
            <p class="text_" v-html="data_list.fallin_status.one"></p>
            <p class="text_ overstriking_">评分：<span class="score_box_" v-for="(item ,index) in score_arr" :key="index"><i class="star_box_"><img :src="item" alt=""></i></span></p>
            <p class="text_" v-html="data_list.fallin_status.three"></p>

            <h2 class="title_text__">双方未来相处分析</h2>
            <p class="text_" v-html="data_list.comego_status.one"></p>
            <p class="text_ overstriking_">评分：<span class="score_box_" v-for="(item ,index) in score_arr2" :key="index"><i class="star_box_"><img :src="item" alt=""></i></span></p>
            <p class="text_" v-html="data_list.comego_status.three"></p>

            <h2 class="title_text__">双方最终感情走向</h2>
            <p class="text_" v-html="data_list.future_status.one"></p>
            <p class="text_ overstriking_">评分：<span class="score_box_" v-for="(item ,index) in score_arr3" :key="index"><i class="star_box_"><img :src="item" alt=""></i></span></p>
            <p class="text_" v-html="data_list.future_status.three"></p>
            <p class="text_">
                温馨提示:若本卦、互卦和变卦三个卦象结果相同
                ，说明你们双方感情未来的走向与当前感情现状
                大体一致，不会有太多变化。
            </p>
        </div>

</div>
</template>

<script>
import resultData from "../../../mixin/nameMatching/result_content/resultData"
export default {
     mixins:[resultData],
}
</script>

<style lang="scss" scoped>
    .analysis{
        .content_box_{
            position: relative;
            width: 7.18rem;
            // height: 8.59rem;
            margin: 0 auto;
            background: #fff;
            border-radius: .30rem;
            box-shadow: 0 0 5px #e8c7d6;
            padding-top: .24rem;
            padding-bottom: .6rem;
            margin-bottom: .47rem;
            margin-top: 0.1rem;
            .flower_img_{
                position: absolute;
                width: 7.18rem;
                top: 0;
            }
            .title_bg_text_{
                width: 4.47rem;
                height: .73rem;
                margin: 0 auto;
                text-align: center;
                color: #C4393E;
                font-size: .38rem;
                font-weight: bold;
                line-height: .73rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG544.jpeg') no-repeat;
                background-size: 100% 100%;
            }
            .name_box_{
                position: relative;
                width: 6.27rem;
                height: 1.41rem;
                margin: .55rem auto;
                .grade_box_{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    width: 1.4rem;
                    height: 1.4rem;
                    font-size: .72rem;
                    font-weight: bold;
                    color: #FFA3B2;
                    text-align: center;
                    line-height: 1.4rem;
                    border-radius: 1.4rem;
                    background: #fff;
                }
                .name_box_common{
                    width: 50%;
                    height: 1.41rem;
                    .name_text{
                        font-size: .36rem;
                        font-weight: bold;
                        color: #3D659A;
                        padding-left: .28rem;
                        margin-top: .35rem;
                        p{
                            // font-size: .18rem;
                            font-size: .23rem;
                            font-weight: 400;
                            // margin-top: .18rem;
                        }
                    }
                }
                .name_left_box{
                    float: left;
                    border-top-left-radius: .1rem;
                    border-bottom-left-radius: .1rem;
                    background: #C7DFFF;
                }
                .name_right_box{
                    float: right;
                    border-top-right-radius: .1rem;
                    border-bottom-right-radius: .1rem;
                    background: #FECCDD;
                    .name_text{
                        text-align: right;
                        padding-right: .28rem;
                        color: #943858;
                    }
                }
            }
            .text_{
                padding: 0 .5rem;
                font-size: .28rem;
                color: #525252;
                line-height: .48rem;margin-top: .35rem;
            }
            .text_:last-child{
                margin-top: .37rem;
                color: #67386F;
            }
            .overstriking_{
                font-weight: bold;
            }
            .score_box_{
                display: inline-block;
                .star_box_{
                    display: inline-block;
                    width: .34rem;
                    margin-right: .08rem;
                }
            }
            .text_red__{
                color: #C4393E;
            }
            
            .title_text__{
                font-size: .28rem;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #C4393E;
                text-align: center;
                margin-top: .36rem;
                margin-bottom: .28rem;
            }
            
            .user_name_text_{
                padding-left: .37rem;
                font-size: .32rem;
                font-weight: bold;
                color: #525252;
                 margin-top: .86rem;
            }
            .user_name_text_:last-child{
                margin-top: 0;
            }
            .img_geomantic_box{
                position: relative;
                height: 2.6rem;
                margin-top: .6rem;
                .div_text_box{
                    width: 1.2rem;
                    // display: inline-block;
                    position: absolute;
                    height: .49rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .49rem;
                }
                    .diamond_box{
                        display: inline-block;
                        width: .49rem;
                        height: .49rem;
                        line-height: .49rem;
                        text-align: center;
                        color: #fff;
                        background: #D74A50;
                    }
                .big_box_{
                    position: absolute;
                    width: 2rem;
                    height: .88rem;
                    font-size: .28rem;
                    color: #525252;
                    line-height: .88rem;
                    box-sizing: border-box;
                    border-left: .05rem solid #D74A50;
                    padding-left: .59rem;
                    .across_{
                        position: absolute;
                        top: 50%;
                        left: -.001rem;
                        transform: translate(0,-50%);
                        width: .44rem;
                        height: .05rem;
                        background: #D74A50;

                    }
                }
                .center_box__{
                    position: absolute;
                    left: 1.8rem;
                }
                .right_box__{
                    position: absolute;
                    right: 3.3rem;
                    .img_box__{
                        position: absolute;
                        left: 1.45rem;
                        top: .4rem;
                        width: .35rem;
                    }
                    
                }
            }
            .gossip_bg_box_{
                width: 7.18rem;
                height: 1.52rem;
                background: url('https://cdn.zhiyileiju.cn/WechatIMG547.jpeg') no-repeat;
                background-size: 100% 100%;
                margin-top: .52rem;
                margin-bottom: .46rem;
                    text-align: center;
                .name_text__{
                    color: #525252;
                    font-size: .36rem;
                    margin-top: .84rem;
                    line-height: .35rem;
                    width: 1.7rem;
                    .designation__{
                        display: inline-block;
                        color: #9C9C9C;
                        font-size: .23rem;
                        // transform: scale(1.4);
                    }
                }
                .man_name_{
                    float: left;
                    margin-left: .4rem;
                }
                .girl_name_{
                    float: right;
                     margin-right: .4rem;
                }
            }
            
        }
    }
</style>